import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import ParamsOptionsType, {convertOptionsToURLSearchParams, getDefaultParams} from '@/services/api/ParamsOptionsType';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import ioc from '@/services/ioc';

export default class ClientApi implements ClientApiInterface {
    private translator: LanguageSwitcherInterface;

    constructor(private readonly apiUrl: string) {
        this.translator = ioc.get('translator') as LanguageSwitcherInterface;
    }

    fetchAddresses(ridNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/addresses/${ridNumber}`,
            method: 'get',
        };
    }

    archiveRequest(requestDeviceId: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/archive/device_request/${String(requestDeviceId)}`,
            method: 'put',
        };
    }

    archiveDevice(deviceId: number): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/archive/device/${deviceId}`,
            method: 'put',
        };
    }

    editAddress(id: number): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/address/${id}`,
            method: 'put',
        };
    }

    getDevices(userRidToken: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/devices/${userRidToken}`,
            method: 'get',
        };
    }

    getDevice(deviceId: number): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/devices/${deviceId}`,
            method: 'get',
        };
    }

    registerRequest(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/register_request',
            method: 'post',
        };
    }

    registerAddress(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/address',
            method: 'post',
        };
    }

    registerDevice(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/devices',
            method: 'post',
        };
    }

    registerSpareParts(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/order/spare_parts',
            method: 'post',
        };
    }

    getClient(ridNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/client/${ridNumber}`,
            method: 'get',
        };
    }

    getClients(paramsOptions: ParamsOptionsType): ApiRequestObjectType {
        const query = convertOptionsToURLSearchParams({
            ...getDefaultParams(),
            ...paramsOptions,
        });

        return {
            hostName: this.apiUrl,
            path: `/clients?${query.toString()}`,
            method: 'get',
        };
    }

    getClientsStats(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/stats/clients',
            method: 'get',
        };
    }

    getClientStats(ridNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/stats/clients/${ridNumber}`,
            method: 'get',
        };
    }

    updateClient(ridNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/client/${ridNumber}`,
            method: 'put',
        };
    }

    deleteAddress(id: number): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/address/${id}`,
            method: 'delete',
        };
    }

    fetchConversations(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/messages',
            method: 'get',
        };
    }

    fetchAdminConversations(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/messages-for-admin',
            method: 'get',
        };
    }

    fetchMessages(conversationNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/messages/${conversationNumber}`,
            method: 'get',
        };
    }

    fetchMyClient(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/client',
            method: 'get',
        };
    }

    sendMessage(): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: '/message',
            method: 'post',
        };
    }

    sendReplyMessage(conversationNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/message/${conversationNumber}`,
            method: 'post',
        };
    }

    loginAnonymous(ridToken: string, clientToken: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/login_anonymous?c=${ridToken}&t=${clientToken}`,
            method: 'get',
        };
    }

    sendAnonymousToken(email: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            path: `/send_anonymous_login_token?email=${email}&lang=${this.translator.getCurrentLanguage()}`,
            method: 'get',
        };
    }
}