import ioc from '@Framework/ioc';
import {Location, NavigationGuardNext} from 'vue-router/types/router';
import {Route} from 'vue-router';
import AuthService from '@/services/AuthService';

type TryRedirectObject = {
    tryFunction: ((args?: Record<string, unknown>) => boolean) | (() => boolean)
    location: Location,
    args?: Record<string, unknown>
};
type RedirectionFunction = (to: Route, from: Route, next: NavigationGuardNext) => void;

export function tryRedirectToRoute(tryObjects: TryRedirectObject[]): RedirectionFunction {
    return (to: Route, from: Route, next: NavigationGuardNext) => {
        for (let i = 0; i < tryObjects.length; i += 1) {
            if (tryObjects[i].tryFunction(tryObjects[i].args)) {
                next(tryObjects[i].location);

                return;
            }
        }
        next();
    };
}

export function hasHash(args?: Record<string, string>): boolean {
    return !!(args && args.hash && window.location.hash === `#${args.hash}`);
}

export function isLogged(): boolean {
    return (ioc.get('service.authService') as AuthService)?.isLogged();
}