import AppLogo from '@/components/AppLogo.vue';
import getVueEnv from '@/env';
import ioc from '@/services/ioc';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

const getBaseApiPath = (): string => getVueEnv('BASE_API_PATH') ?? '';

function getApiPath(): string {
    return `${getBaseApiPath()}/api/v1`;
}

export type AppConfigType = {
    appName: string;
    appLogo: unknown;
    appReturnBaseUrl: string;
    urls: {
        [key: string]: {
            warrantyTermsUrl: string;
            faqUrl: string;
        }
    }
    orderParts: {
        consentsUrls: {
            [key: string]: {termsUrl: string; privacyPolicyUrl: string}
        };
        consents: {application: string};
        opcApiKey: string;
        language: string;
        theme: string;
        opcApi: string;
        api: string;
        availableCountries: string[];
        searchToken: string;
    };
    api: {apiUri: string; opc: {opcApiKey: string; opcApi: string; searchToken: string}; filesBaseUri: string};
    // eslint-disable-next-line max-len
    cts: {api: string, searchToken: string, consentsUrls: Record<string, Record<string, string>>, messages: Record<string, Record<string, string>>};
};

export default (): AppConfigType => (
    {
        get appName() {
            const translator = ioc.get('translator') as LanguageSwitcherInterface;
            const t = translator.t.bind(translator);

            return t('cts.header.name');
        },
        appLogo: AppLogo,
        appReturnBaseUrl: window.location.origin,
        urls: {
            pl: {
                warrantyTermsUrl: 'https://old.rawlplug.com/pl/serwis-narzedzi/warunki-gwarancji',
                faqUrl: 'https://old.rawlplug.com/pl/serwis-narzedzi/faq-serwis-narzedzi',
            },
            en: {
                warrantyTermsUrl: 'https://old.rawlplug.com/en/tool-service/warranty-conditions',
                faqUrl: 'https://old.rawlplug.com/en/tool-service/faq-tool-service',
            },
        },
        orderParts: {
            api: getApiPath(),
            searchToken: '123tokenctsportal',
            language: 'pl',
            availableCountries: ['PL', 'CZ'],
            theme: 'rawlplug',
            opcApi: 'https://opc.rawlplug.com/api',
            opcApiKey: 'mLJYvcdalS',
            consents: {
                application: 'cts',
            },
            consentsUrls: {
                pl: {
                    // eslint-disable-next-line max-len
                    termsUrl: 'https://old.rawlplug.com/pl/regulamin-uslug-centralnego-serwisu-narzedzi',
                    // eslint-disable-next-line max-len
                    privacyPolicyUrl: 'https://old.rawlplug.com/pl/polityka-prywatnosci-i-bezpieczenstwa#administrators',
                },
                en: {
                    // eslint-disable-next-line max-len
                    termsUrl: 'https://old.rawlplug.com/en/terms-and-conditions-of-technical-support-services',
                    // eslint-disable-next-line max-len
                    privacyPolicyUrl: 'https://old.rawlplug.com/en/privacy-and-security-policy#administrators',
                },
            },
        },
        api: {
            apiUri: getApiPath(),
            filesBaseUri: getBaseApiPath(),
            opc: {
                opcApi: 'https://opc.rawlplug.com/api',
                opcApiKey: '123tokenctsportal',
                searchToken: '123tokenctsportal',
            },
        },
        cts: {
            api: getApiPath(),
            searchToken: '123tokenctsportal',
            consentsUrls: {
                pl: {
                    // eslint-disable-next-line max-len
                    termsUrl: 'https://www.rawlplug.com/pl/regulamin-uslug-centralnego-serwisu-narzedzi',
                    // eslint-disable-next-line max-len
                    privacyPolicyUrl: 'https://www.rawlplug.com/pl/polityka-prywatnosci-i-bezpieczenstwa#administrators',
                },
            },
            messages: {
                pl: {
                    // eslint-disable-next-line max-len
                    registerAccountInfo: 'W celu zarejestrowania urządzenia konieczne jest założenie konta w serwisie. Poniżej podaj dane. Jeżeli posiadasz już konto, <a href="/login" target="_blank">log in</a>',
                },
                en: {
                    // eslint-disable-next-line max-len
                    registerAccountInfo: 'In order to register the device, you need to create an account in the service. Enter the data below. If you already have an account, <a href="/login" target="_blank">log in</a>',
                },
            },
        },
    }
);