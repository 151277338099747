import ioc from '@/services/ioc';
import {Store} from 'vuex';
import installRouter from '@Framework/installRouter';
import {Vue} from 'vue-property-decorator';

const authService = () => ioc.get('service.authService') as any;

export default (store: Store<any>) => {
    const router = installRouter(Vue);
    // WEB-10663 - fix for router.push error handling
    const originalPush = router.push;
    router.push = function pushAdapter(...args: Parameters<typeof originalPush>) {
        // @ts-ignore
        return originalPush.call(this, ...args).catch((error) => {
            if (
                error
                && /^Redirected when going from "[^"]+" to "[^"]+" via a navigation guard\.$/.test(error?.message ?? '')
            ) {
                console.warn(`consumed router exception: ${error}`);
            }
        });
    };
    ioc.set(
        'router',
        // eslint-disable-next-line func-names
        function () {
            return router;
        },
        {},
    );

    router.beforeEach(async (to, from, next) => {
        const forPublic = to.meta?.routeFor?.includes('PUBLIC');
        const forAdmins = to.meta?.routeFor?.includes('ADMIN');
        const forClients = to.meta?.routeFor?.includes('CLIENT');
        const authRequired = !forPublic;
        const loggedIn = authService().isLogged();
        const isAdmin = await store.dispatch('auth/checkIfAdmin');

        if (authRequired && !loggedIn) {
            // Jeżeli użytkownik nie jest zalogowany, a próbuje wejść na stronę wymagającą logowania
            // jak np. panel admina lub panel użytkownika
            next('/login');
        } else if (forAdmins && !isAdmin) {
            // Jeżeli użytkownik jest zalogowany, a próbuje wejść na stronę admina
            // a nie jest adminem to przekieruj go na stronę główną
            next('/');
        } else if (forClients && isAdmin) {
            // Jeżeli użytkownik jest zalogowany, a próbuje wejść na stronę klienta
            // a jest adminem to przekieruj go na stronę z listą zgłoszeń
            next('/admin/requests/open');
        } else {
            next();
        }
    });

    return router;
};